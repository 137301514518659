var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transferRecord"},[_c('van-list',{attrs:{"finished":_vm.state.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.state.loading),callback:function ($$v) {_vm.$set(_vm.state, "loading", $$v)},expression:"state.loading"}},_vm._l((_vm.recordList),function(item,index){return _c('div',{key:index,staticClass:"oneTransferRecord"},[_c('p',[_c('span',{staticClass:"co_noText"},[_vm._v("订单号:"+_vm._s(item.pr_no))])]),_c('div',{staticClass:"flex4"},[_c('p',{staticClass:"flex3 co_priceText"},[_c('span',[_vm._v("打款金额 : "),_c('span',{staticClass:"colorRed"},[_vm._v(_vm._s(item.pr_price))])]),_c('span',[_vm._v("收款账号 : "+_vm._s(item.u_ali_account))]),_c('span',[_vm._v("收款人姓名 : "+_vm._s(item.u_ali_realname))]),_c('span',[_vm._v("打款时间 : "+_vm._s(_vm._f("ts2Date")(item.pr_updatetime)))])]),_c('p',[_c('span',{staticClass:"state",style:({
            color:
              item.pay_status == 0
                ? '#0882ea'
                : item.pay_status == 1
                  ? '#04d932'
                  : '#ff3e13',
          })},[_vm._v(" "+_vm._s(item.pay_status == 0 ? "打款中" : item.pay_status == 1 ? "已打款" : item.pay_status == 2 ? "打款失败" : "正在重新打款"))])])]),(item.pay_status != 0)?_c('div',{staticClass:"flex2"},[(item.pay_status == 2 || item.pay_status == 3)?_c('span',{staticStyle:{"color":"#f83130","font-size":"10px"}},[_vm._v("备注:")]):_vm._e(),_c('span',{staticClass:"co_noText"},[_vm._v("添加时间:"+_vm._s(_vm._f("ts2Date")(item.pr_addtime)))])]):_vm._e(),(item.pay_status == 2 || item.pay_status == 3)?_c('p',[_c('span',{staticClass:"co_verify_msg"},[_vm._v(_vm._s(item.pr_remark))])]):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }