<!-- 打款记录 -->
<template>
  <div class="transferRecord">
    <van-list v-model:loading="state.loading" :finished="state.finished" finished-text="没有更多了" @load="onLoad">
      <!-- <header class="headerTitle">提现记录</header> -->
      <div class="oneTransferRecord" v-for="(item, index) in recordList" :key="index">
        <p>
          <span class="co_noText">订单号:{{ item.pr_no }}</span>
        </p>
        <div class="flex4">
          <p class="flex3 co_priceText">
            <span>打款金额 :
              <span class="colorRed">{{ item.pr_price }}</span></span>

            <!-- <span v-if="item.bank_name">提现方式 :
              {{
                item.co_type == 1
                  ? "支付宝"
                  : item.co_type == 2
                  ? "微信"
                  : item.co_type == 3
                  ? "银行卡"
                  : ""
              }}</span> -->

            <span>收款账号 : {{ item.u_ali_account }}</span>

            <span>收款人姓名 : {{ item.u_ali_realname }}</span>
            <span>打款时间 : {{ item.pr_updatetime | ts2Date() }}</span>
          </p>
          <p>
            <span class="state" :style="{
              color:
                item.pay_status == 0
                  ? '#0882ea'
                  : item.pay_status == 1
                    ? '#04d932'
                    : '#ff3e13',
            }">
              {{
                item.pay_status == 0
                ? "打款中"
                : item.pay_status == 1
                  ? "已打款"
                  : item.pay_status == 2
                    ? "打款失败"
                    : "正在重新打款"
              }}</span>
          </p>
        </div>
        <div class="flex2" v-if="item.pay_status != 0">
          <span v-if="item.pay_status == 2 || item.pay_status == 3" style="color:#f83130 ;font-size: 10px">备注:</span>
          <span class="co_noText">添加时间:{{ item.pr_addtime | ts2Date() }}</span>
        </div>
        <p v-if="item.pay_status == 2 || item.pay_status == 3">
          <span class="co_verify_msg">{{ item.pr_remark }}</span>
        </p>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recordList: [],
      state: {
        loading: false,
        finished: false,
      },
      reqParams: {
        page: 1,
        limit: 10,
      },
      isPullRefresh: true,
    };
  },

  components: {},

  mounted() { },

  methods: {
    async onLoad() {
      console.log("上拉加载", this.isPullRefresh);

      if (this.isPullRefresh) {
        this.isPullRefresh = false;
        this.getRecordList();
      }
    },
    async getRecordList() {
      console.log("请求");
      this.$axios
        .get("/api/account/getPaymentRecordList", {
          params: this.reqParams,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.reqParams.page++;
            this.recordList = this.recordList.concat(res.data.rows);
            // 数据全部加载完成
            if (this.recordList.length >= res.data.total) {
              this.state.finished = true;
            }
          }
          this.isPullRefresh = true;
          this.state.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPullRefresh = true;
          this.state.loading = false;
        });
    },
  },
  filters: {
    ts2Date: (timestamp) => {
      if (timestamp == 0) {
        return "";
      }
      let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
        h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":",
        m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":",
        s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
<style lang="scss">
.transferRecord {
  .headerTitle {
    font-size: 20px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
  }

  .oneTransferRecord {
    margin: 5px 10px 10px 10px;
    margin-top: 3px;
    border-bottom: 1px solid rgb(31, 31, 31);
    padding-bottom: 5px;

    .co_noText {
      color: gray;
      font-size: 10px;
    }

    .co_priceText {
      span {
        font-size: 15px;
        color: rgb(19, 4, 4);
      }

      .colorRed {
        color: #f83130;
      }
    }

    p {
      padding: 0px;
      margin: 0px;
    }

    .co_verify_msg {
      display: inline-block;
      white-space: pre-wrap;
      word-break: normal;
      display: block;
      word-wrap: break-word;
      overflow: hidden;
      color: #f83130;
      font-size: 10px;
    }

    .state {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .flex4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex2 {
    display: flex;
    justify-content: space-between;
  }

  .flex3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
</style>
